<template>
  <div>
    <div class="card p-1">
      <div class="row justify-content-between">
        <div class="h2 ml-1" style="color: #558cef">หน้าเเรก</div>
        <div class="mr-1"></div>
      </div>
    </div>
    <div>
      <b-overlay :show="show" rounded="sm">
        <div class="row justify-centent-between">
          <div class="col-6">
            <statistic-card-vertical
              icon="PackageIcon"
              :statistic="list_data_a.toString()"
              statistic-title="รับเข้าวัสดุ"
              color="info"
            />
          </div>
          <div class="col-6">
            <statistic-card-vertical
              icon="PackageIcon"
              :statistic="list_data_b.toString()"
              statistic-title="เบิกวัสดุ"
              color="info"
            />
          </div>
          <div class="col-6 col-md-3">
            <statistic-card-vertical
              icon="InboxIcon"
              :statistic="list_data_c.toString()"
              statistic-title="รับเข้าทรัพย์สิน"
              color="danger"
            />
          </div>
          <div class="col-6 col-md-3">
            <statistic-card-vertical
              icon="InboxIcon"
              :statistic="list_data_d.toString()"
              statistic-title="เบิกทรัพย์สิน"
              color="danger"
            />
          </div>
          <div class="col-6 col-md-3">
            <statistic-card-vertical
              icon="InboxIcon"
              :statistic="list_data_e.toString()"
              statistic-title="ส่งคืนทรัพย์สิน"
              color="danger"
            />
          </div>
          <div class="col-6 col-md-3">
            <statistic-card-vertical
              icon="InboxIcon"
              :statistic="list_data_f.toString()"
              statistic-title="ยืม/คืนทรัพย์สิน"
              color="danger"
            />
          </div>
        </div>
      </b-overlay>
      <!-- <b-overlay :show="show" rounded="sm">
        <statistic-card-vertical
          icon="UsersIcon"
          :statistic="subscribersGained.toString()"
          statistic-title="User"
          color="info"
        />
        <statistic-card-vertical
          icon="UsersIcon"
          :statistic="subscribersGained.toString()"
          statistic-title="User"
          color="info"
        />
      </b-overlay> -->
    </div>
  </div>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { BOverlay } from "bootstrap-vue";
import axios from "axios";
import API from "@/views/connectDB/condb";

export default {
  components: {
    BOverlay,
    StatisticCardVertical,
  },
  data() {
    return {
      show: false,
      subscribersGained: "1",
      list_data_a: 0,
      list_data_b: 0,
      list_data_c: 0,
      list_data_d: 0,
      list_data_e: 0,
      list_data_f: 0,
    };
  },
  created() {
    this.get_list_a();
    this.get_list_b();
    this.get_list_c();
    this.get_list_d();
    this.get_list_e();
    this.get_list_f();
  },
  methods: {
    async get_list_f() {
      const { access_token } = await this.access_token();
      const url2 = `${API}borrowHistory`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url2, head);
      this.list_data_f = res.data.message.total;
    },
    async get_list_e() {
      const { access_token } = await this.access_token();
      const url = `${API}returnsList`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.list_data_e = res.data.message.total;
    },
    async get_list_d() {
      const { access_token } = await this.access_token();
      const url = `${API}withdrawHistory`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.list_data_d = res.data.message.total;
    },
    async get_list_c() {
      const { access_token } = await this.access_token();
      const url = `${API}receiveAccess?_sort=-1&_page=${this.currentPage}&_limit=100`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.list_data_c = res.data.message.total;
      this.show = false;
    },
    async get_list_b() {
      const { access_token } = await this.access_token();
      this.show = true;
      const url = `${API}historyOfMaterial`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.list_data_b = res.data.message.total;
      this.show = false;
    },
    async get_list_a() {
      const { access_token } = await this.access_token();
      this.show = true;
      const url = `${API}materialAdmits?register_name=รับเข้าวัสดุ`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      this.list_data_a = res.data.message.total;
      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style lang="scss"></style>
